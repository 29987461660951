.skills  {
    display: flex;
    flex-direction: row;
    justify-content: center;
    flex-wrap: wrap;

    align-items: center;
    padding: 0 10px;
    gap: 1em;
    font-size: 0.8em;
    text-transform: uppercase;

    margin-bottom: 3em;
}

.skills__icon {
    max-width: 2em;
}

.skills__titulo {
    font-size: 1em;
    font-weight: 500;
    padding: 1em 0 1.5em;
}

.skills__bigode {
    font-size: 3em;
    font-weight: 300;
    margin: 0 0 0.5em;
}