.banner {
  background: url('../Banner/assets/background-banner.png');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: left;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  color: white;
  font-size: 1rem;
}

.banner h1 {
  font-size: 1.5rem;
}

.banner h2 {
  font-size: 1.7rem;
  font-weight: bold;
  text-transform: uppercase;

  margin: 1em 0;

  background: var(--degrade-background-da-fonte);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
}

@media screen and (min-width:1024px) {
  .banner h1 {
    font-size: 2.5rem;
  }

  .banner h2 {
    font-size: 2.7rem;
  }
}